<fuse-loading-bar></fuse-loading-bar>

<fuse-vertical-navigation class="dark print:hidden"
                          [mode]="isScreenSmall ? 'over' : 'side'"
                          [name]="'mainNavigation'"
                          [navigation]="navigation.default"
                          [opened]="!isScreenSmall" style="background-color: #000f70 !important">
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex flex-col items-center w-full p-4">
            <img src="https://assets-global.website-files.com/5efd6630df938ab42b6693ce/5efd6630df938aef9d6693f0_international-icon.svg" style="opacity: 0.3">
            <div class="relative w-24 h-24">
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{user?.name}}
                </div>
                <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{user?.email}}
                </div>
                <span style="font-size:9px;font-weight:300">ver. {{version}}</span>
            </div>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<div class="flex flex-col flex-auto w-full min-w-0">
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
        </div>
    </div>
    <div class="flex flex-col flex-auto">
        <router-outlet *ngIf="true"></router-outlet>
    </div>
</div>

import { NgModule } from '@angular/core';

import { environment } from 'environments/environment';
import { AuthModule } from 'angular-auth-oidc-client';

@NgModule({
  imports: [
    AuthModule.forRoot({
      // loader: {
      //   provide: StsConfigLoader,
      //   useFactory: httpLoaderFactory,
      //   //deps: [HttpClient, ConfigurationService],
      // },
      config: {
       authority: environment.authority,
       clientId: 'officemapsadmincenter',
       responseType: 'code',
       scope: 'openid profile offline_access email admincenterapi roles',

       redirectUrl: window.location.origin +'/start/callback',
       postLogoutRedirectUri: window.location.origin + '/end',
       //logLevel: LogLevel.Debug,
       historyCleanupOff: false
      }
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule { }

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { SearchUsersQueryResultsDto } from '../models/search-users-query-results-dto';
import { UserDetails } from '../models/user-details';

@Injectable({ providedIn: 'root' })
export class UsersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `usersSearchGet()` */
  static readonly UsersSearchGetPath = 'users/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSearchGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersSearchGet$Plain$Response(
    params?: {
      SearchTerm?: string;
      Page?: number;
      PageSize?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SearchUsersQueryResultsDto>> {      
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersSearchGetPath, 'get');
    if (params) {
      rb.query('SearchTerm', params.SearchTerm, {"style":"form"});
      rb.query('Page', params.Page, {"style":"form"});
      rb.query('PageSize', params.PageSize, {"style":"form"});
    }

    rb.header("Authorization", 'BEARER ' + sessionStorage.getItem('token'));
    //rb.header("client_Id", "officemapsadmincenter");

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SearchUsersQueryResultsDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersSearchGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersSearchGet$Plain(
    params?: {
      SearchTerm?: string;
      Page?: number;
      PageSize?: number;
    },
    context?: HttpContext
  ): Observable<SearchUsersQueryResultsDto> {

    return this.usersSearchGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchUsersQueryResultsDto>): SearchUsersQueryResultsDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSearchGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersSearchGet$Json$Response(
    params?: {
      SearchTerm?: string;
      Page?: number;
      PageSize?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SearchUsersQueryResultsDto>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersSearchGetPath, 'get');
    if (params) {
      rb.query('SearchTerm', params.SearchTerm, {"style":"form"});
      rb.query('Page', params.Page, {"style":"form"});
      rb.query('PageSize', params.PageSize, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SearchUsersQueryResultsDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersSearchGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersSearchGet$Json(
    params?: {
      SearchTerm?: string;
      Page?: number;
      PageSize?: number;
    },
    context?: HttpContext
  ): Observable<SearchUsersQueryResultsDto> {
    return this.usersSearchGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchUsersQueryResultsDto>): SearchUsersQueryResultsDto => r.body)
    );
  }

  /** Path part for operation `usersPersonIdGet()` */
  static readonly UsersPersonIdGetPath = '/users/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersPersonIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersPersonIdGet$Plain$Response(
    params: {
      personId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserDetails>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersPersonIdGetPath, 'get');
    if (params) {
      rb.path('personId', params.personId, {"style":"simple"});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDetails>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersPersonIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersPersonIdGet$Plain(
    params: {
      personId: string;
    },
    context?: HttpContext
  ): Observable<UserDetails> {
    return this.usersPersonIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDetails>): UserDetails => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersPersonIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersPersonIdGet$Json$Response(
    params: {
      personId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserDetails>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersPersonIdGetPath, 'get');
    if (params) {
      rb.path('personId', params.personId, {"style":"simple"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDetails>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersPersonIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersPersonIdGet$Json(
    params: {
      personId: string;
    },
    context?: HttpContext
  ): Observable<UserDetails> {
    return this.usersPersonIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDetails>): UserDetails => r.body)
    );
  }

}

/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        subtitle: '',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'dashboards.home',
                title: 'Home',
                type : 'basic',
                icon : 'heroicons_outline:home',
                link : '/dashboard/home'
            }
        ]
    },
    {
        id      : 'applications',
        title   : 'Applications',
        subtitle: '',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'applications.usersearch',
                title: 'User Search',
                type : 'basic',
                icon : 'heroicons_outline:search',
                link : '/application/usersearch'
            },
            {
                id   : 'applications.instancesearch',
                title: 'Instance Search',
                type : 'basic',
                icon : 'heroicons_outline:search',
                link : '/application/instancesearch'
            },
            {
                id   : 'applications.logout',
                title: 'Log Out',
                type : 'basic',
                icon : 'heroicons_outline:logout',
                link : '/sign-out'
            }
        ]
    }
    
];
//export const compactNavigation: FuseNavigationItem[] = [
//    {
//        id      : 'dashboards',
//        title   : 'Dashboards',
//        subtitle: 'admin centre dashboards',
//        type    : 'group',
//        icon    : 'heroicons_outline:home',
//        children: [
//            {
//                id   : 'dashboards.home',
//                title: 'Home',
//                type : 'basic',
//                icon : 'heroicons_outline:clipboard-check',
//                link : '/dashboards/home'
//            }
//        ]
//    },
//    {
//        id      : 'applications',
//        title   : 'Applications',
//        subtitle: 'applications',
//        type    : 'group',
//        icon    : 'heroicons_outline:home',
//        children: [
//            {
//                id   : 'applications.usersearch',
//                title: 'User Search',
//                type : 'basic',
//                icon : 'heroicons_outline:clipboard-check',
//                link : '/applications/usersearch'
//            },
//            {
//                id   : 'applications.logout',
//                title: 'Logout',
//                type : 'basic',
//                icon : 'heroicons_outline:logout',
//                link : '/sign-out'
//            }
//        ]
//    }
//];
//export const horizontalNavigation: FuseNavigationItem[] = [
//    {
//        id      : 'dashboards',
//        title   : 'Dashboards',
//        subtitle: 'admin centre dashboards',
//        type    : 'group',
//        icon    : 'heroicons_outline:home',
//        children: [
//            {
//                id   : 'dashboards.home',
//                title: 'Home',
//                type : 'basic',
//                icon : 'heroicons_outline:clipboard-check',
//                link : '/dashboards/home'
//            }
//        ]
//    },
//    {
//        id      : 'applications',
//        title   : 'Applications',
//        subtitle: 'applications',
//        type    : 'group',
//        icon    : 'heroicons_outline:home',
//        children: [
//            {
//                id   : 'applications.usersearch',
//                title: 'User Search',
//                type : 'basic',
//                icon : 'heroicons_outline:clipboard-check',
//                link : '/applications/usersearch'
//            },
//            {
//                id   : 'applications.logout',
//                title: 'Logout',
//                type : 'basic',
//                icon : 'heroicons_outline:chart-pie',
//                link : '/sign-out'
//            }
//        ]
//    }
//];

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { OidcSecurityService } from 'angular-auth-oidc-client';

import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';

import { catchError, Observable, of, switchMap, throwError } from 'rxjs';

@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;

    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private oidcSecurityService: OidcSecurityService
    )
    {
    }

    set token(token: string)
    {
        localStorage.setItem('token', token);
    }

    get token(): string
    {
        return localStorage.getItem('token') ?? '';
    }

    signIn(credentials: { email: string; password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post('api/auth/sign-in', credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.token = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return a new observable with the response
                return of(response);
            })
        );
    }
    signInUsingToken(): Observable<any>
    {
        // Sign in using the token
        return this._httpClient.post('api/auth/sign-in-with-token', {
            accessToken: this.token
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if ( response.accessToken )
                {
                    this.token = response.accessToken;
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            })
        );
    }
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('token');

        // Set the authenticated flag to false
        this._authenticated = false;

        return this.oidcSecurityService.logoff();
        /*
            .subscribe(result => {
                console.warn(result);
            })
        */

        /*return of(true);*/
    }

    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.token )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.token) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    isTokenValid() {        
        return !AuthUtils.isTokenExpired(sessionStorage.getItem('token'));
    }
}

import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard 
{
    constructor(
        private _oidcSecurityService: OidcSecurityService,
        private _authService: AuthService,
        private _router: Router
    ) {
    }

    canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._check(segments);
    }

    private _check(segments: UrlSegment[]): Observable<boolean | UrlTree> {
        if (this._authService.isTokenValid()) {
            return of(true);
        } else {
            return this._oidcSecurityService.checkAuth().pipe(
                switchMap(({ isAuthenticated, userData, accessToken }) => {
                    if (isAuthenticated) {
                        return of(true);
                    } else {
                        if (window.location.href.indexOf("/forbidden") > -1) {
                            return of(true);
                        } else {
                            return of(this._router.parseUrl("start"));
                        }
                    }
                })
            );
        }

        //// Check the authentication status
        //return this._authService.check().pipe(
        //    switchMap((authenticated) => {

        //        // If the user is not authenticated...
        //        if ( !authenticated )
        //        {
        //            // Redirect to the sign-in page with a redirectUrl param
        //            const redirectURL = `/${segments.join('/')}`;
        //            const urlTree = this._router.parseUrl(`sign-in?redirectURL=${redirectURL}`);

        //            return of(urlTree);
        //        }

        //        // Allow the access
        //        return of(true);
        //    })
        //);
    }
}
